const types = {
    authCheckingFinish: '[auth] Finish checking login state',
    authStartLogin: '[auth] Start login',
    authLogin: '[auth] Login',
    authStartRegister: '[auth] Start Register',
    authStartTokenRenew: '[auth] Start token renew',
    authLogout: '[auth] Logout',

    alarmasStartActivas: '[alarmasActivas] Guardar nuevas alarmas activas',
    alarmasStartLogout: '[alarmasActivas] Vaciar alarmas activas logout',
    consultarHistorialAlarmas: '[HistorialAlarmas] Consultar historial alarmas',

    iniciarTurno: '[turno] Login Turno',
    terminarTurno: '[turno] Logout Turno'
};

export default types;