import types from "../types/types";

const initialState = {
    turnoActivo: localStorage.getItem('turnoActivo'),
};

const turnoReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.iniciarTurno:
            return {
                turnoActivo: 'true'
            };
        case types.sinTurno:
            return {
               turnoActivo: false
            };
        case types.terminarTurno:
            return {
               turnoActivo: false
            };
        default:
            return state;
    };
};

export default turnoReducer;