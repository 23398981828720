import types from "../types/types";

const initialState = {
    checking: true,
    uid: null,
    name: null,
    token: null,
    edificioName: localStorage.getItem('edificioName'),
    edificioDireccion: localStorage.getItem('edificioDireccion'),
    edificioId: localStorage.getItem('edificioId'),
    logueando: false,
    errorlogin: false,
};

const authReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.authLogin:
            return {
                ...state,
                checking: false,
                ...action.payload
            };
        case types.authCheckingFinish:
            return {
                ...state,
                checking: false
            };
        case types.authLogout:
            return {
                checking: false
            };
        default:
            return state;
    };
};

export default authReducer;