import { combineReducers } from 'redux';

import turnoReducer from 'reducers/turnoReducer';
import settings from './settings/reducer';
import menu from './menu/reducer';
import authUser from './auth/reducer';
import authReducer from '../reducers/authReducer';
import alarmasReducer from '../reducers/alarmasReducer';

const reducers = combineReducers({
  menu,
  settings,
  authUser,
  authReducer,
  alarmasReducer,
  turnoReducer
});

export default reducers;
