import types from "../types/types";

const initialState = {
    alarmasActivas: [],
    historialAlarmas: []
};

const alarmasReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.alarmasStartActivas:
            return {
                ...state,
                alarmasActivas: action.payload
            };
        case types.alarmasStartLogout:
            return {
                alarmasActivas: [],
                historialAlarmas: []
            };
        case types.consultarHistorialAlarmas:
            return {
                ...state,
                historialAlarmas: action.payload
            };
        default:
            return state;
    };
};

export default alarmasReducer;